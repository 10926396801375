import React from 'react';
import * as classes from './CookieBanner.module.css';

const CookieBanner = ({acceptHandler}) => (
  <div className={classes.container}>
    <div className={classes.cookieBannerMain}>
      {/* <p>
        We use cookies to make your experience of our websites better. By using and further
        navigating this website you accept this. Detailed information about the use of cookies on
        this website is available by clicking on{' '}
        <a href="/privacy/" target="_blank" className={classes.moreInfo}>
          more information.
        </a>
      </p>
      <div className={classes.buttonDiv}>
        <button type="button" className={classes.button} onClick={acceptHandler}>
          Accept All
        </button>
      </div> */}
    </div>
  </div>
);

export default CookieBanner;
