import React, {Component} from 'react';
import Layout from '../../components/Shared/Layout';
import DecoristRoomBuilder from '../../components/Portfolio/decorist';
import '../../assets/css/decorist.css';
import * as ReactGA from 'react-ga';

class DecoristRoomBuilderPage extends Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Portfolio/DecoristRoomBuilder',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <div>
        <Layout headerPosition="fixed">
          <DecoristRoomBuilder />
        </Layout>
      </div>
    );
  }
}
export default DecoristRoomBuilderPage;
