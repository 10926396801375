import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';
import banner from '../../assets/images/decorist/new/decorist-banner.webp';
import decoristOne from '../../assets/images/decorist/new/decorist-1.webp';
import decoristTwo from '../../assets/images/decorist/new/decorist-2.webp';
import decoristThree from '../../assets/images/decorist/new/decorist-3.webp';
import decoristFourA from '../../assets/images/decorist/new/decorist-4-a.webp';
import decoristFourB from '../../assets/images/decorist/new/decorist-4-b.webp';
import decoristFourC from '../../assets/images/decorist/new/decorist-4-c.webp';
import decoristFourD from '../../assets/images/decorist/new/decorist-4-d.webp';
import decoristFive from '../../assets/images/decorist/new/decorist-5.webp';
import decoristSix from '../../assets/images/decorist/new/decorist-6.webp';
import decoristSeven from '../../assets/images/decorist/new/decorist-7.webp';
import decoristEightA from '../../assets/images/decorist/new/decorist-8-a.webp';
import decoristEightB from '../../assets/images/decorist/new/decorist-8-b.webp';
import decoristEightC from '../../assets/images/decorist/new/decorist-8-c.webp';
import decoristEightD from '../../assets/images/decorist/new/decorist-8-d.webp';
import decoristEightE from '../../assets/images/decorist/new/decorist-8-e.webp';
import decoristEightF from '../../assets/images/decorist/new/decorist-8-f.webp';
import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import review from '../../assets/images/decorist/new/review-decorist.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Decorist extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <img className="case-study-banner" src={banner} alt="banner" />
          </section>
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 p-0">
                <div className="title p-0 section-margin">
                  Interior Design App For Photorealistic Rendering
                </div>
                <div className="subtitle section-margin">
                  Reducing the processing time to boost designers’ productivity
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#8B8C87'}}> DECORIST</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Build the Room Builder application to automate the entire process of room
                      design and rendering for Decorist designers.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed a Unity application by automating and optimizing the pipeline
                      workflows for generating photorealistic room images.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>

                    <p className="desc">
                      The user-friendly application helped reduce the processing time from two days
                      to less than two hours.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://www.architecturaldigest.com/story/online-interior-design-services-decorist-homepolish-laurel-wolf-modsy-hutch"
                      target="_blank" rel="noreferrer"
                    >
                      The online interior design services to know
                    </a>{' '}
                    - Architectural Digest
                    <br />
                    <a
                      className="press-desc"
                      href="https://www.vogue.com/article/digital-interior-design-home-makeovers"
                      target="_blank" rel="noreferrer"
                    >
                      The best digital interior design sites to help you create your dream home
                    </a>{' '}
                    - Vogue
                  </p>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/ljOgzwsufU8"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      Decorist was founded in 2014 to offer a quick and affordable solution to
                      interior design challenges through a virtual design service. It’s a digital
                      platform that pairs clients with interior designers.
                    </p>
                    <br />
                    <p className="description">
                      The designers would create a layout with specific measurements of the client’s
                      floor plan and select products matching their budget and aesthetic to create
                      concepts. Using those concepts,  technical artists would then generate
                      photorealistic images of the room through professional software such as 3ds
                      Max and VRay. A detailed shopping list of the furniture and decor would be
                      passed on to the client and the whole process would take a couple of days.
                    </p>
                    <br />
                    <p className="description">
                      Decorist engaged Tintash to develop the Room Builder application. They wanted
                      to automate the whole process of generating photorealistic renders and make it
                      faster and more efficient through a user-friendly app for their designers.
                    </p>
                    <br />
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex my-5 section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        A photorealistic image of a room created in 3ds Max and rendered with VRay
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={decoristOne} alt="jobbox" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      The objective was clearly laid out: the Tintash team was to create an
                      application in Unity that would allow Decorist’s designers to quickly design a
                      room in an optimized environment and efficiently generate photorealistic
                      renders. In order to achieve the targeted processing time, it was essential to
                      reduce the rendering time as well as the time taken to design a room. This
                      entailed designing a rendering process that would handle item models
                      efficiently and reduce performance issues in the Unity application.
                    </p>
                    <br />
                    <p className="description">
                      The team had to overcome the following challenges to meet these objectives.
                      Firstly, they had to finalize the tech stack and then rapidly prototype the 3D
                      room creation procedurally in 3ds Max using max scripting.
                    </p>
                    <br />
                  </div>
                  <div className="col-12 p-0 d-flex section-margin">
                    <div
                      className="col-lg-6 col-md-9 col-12 p-0 d-flex justify-content-center"
                      style={{marginLeft: 'auto', marginRight: 'auto'}}
                    >
                      <img className="wrap-image" src={decoristTwo} alt="jobbox" />
                      <div className="image-description wrap-caption">
                        Procedural room generation in 3ds Max with json file
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      We also had to build a scalable solution for managing multiple render requests
                      using SQS queues and load balancer, and reducing the AWS cost by 50% with an
                      on-demand instance usage. Lastly, we had to automate pipeline workflows which
                      included render generation as well as conversion of obj files to unity asset
                      bundles through Jenkins.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        Render generation automation using Jenkins
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={decoristThree} alt="jobbox" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Because of our limited prior experience in automation and photorealistic
                      rendering, the team took a test-driven R&D approach.  We listed down the most
                      technically challenging areas in the project – both on the app side and around
                      automation – and spent the first few weeks solving for those as separate
                      pieces of a larger system.
                    </p>
                    <br />
                    <p className="description">
                      <span style={{fontWeight: '700'}}>Product Design: </span> our designers
                      collaborated with the Decorist team to figure out the application’s
                      wireframes, requirements, and processes. We followed that up with an intensive
                      review process to ensure a UI design of the highest quality. We also initiated
                      multiple cycles of user testing to improve and optimize the user experience
                      for designers.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        Wireframes developed for home screen
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={decoristFourA} alt="jobbox" />
                    </div>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        UI design of home screen
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={decoristFourB} alt="jobbox" />
                    </div>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        Wireframes developed for floor plan screen
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={decoristFourC} alt="jobbox" />
                    </div>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        UI design for floor plan screen
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={decoristFourD} alt="jobbox" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      <span style={{fontWeight: '700'}}>Technical Design: </span> since the Room
                      Builder app was developed in Unity, the high quality item models in Max needed
                      to be mapped to low quality and optimized for Unity. We created asset
                      production guidelines for Decorist’s 3D artists so that items would show up as
                      accurately as possible – in terms of texture, color and geometry – in the
                      application.
                    </p>
                    <br />
                    <p className="description">
                      We used remote automation machines to create the final photorealistic images
                      to be shown to customers and process the item models for use in the Unity app.
                      We also designed and created an online inventory system that would store the
                      3D models and information of the items to be used in rooms. That portal
                      incorporated databases containing information against each item such as its
                      ID, category, and vendor.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">Architectural Diagram</div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={decoristFive} alt="jobbox" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      <span style={{fontWeight: '700'}}>Asset Workflow: </span>in order to show the
                      room items being designed in the Unity app, we defined an asset workflow that
                      mapped item models in the Unity app to item models in 3ds Max which would show
                      up in the final renders. An asset bundle was uploaded on the inventory
                      management system that allowed the app to access item models.
                    </p>
                    <br />
                    <p className="description">
                      <span style={{fontWeight: '700'}}>Rendering Quality Assurance: </span> we came
                      up with a novel way to integrate Slack with our rendering process. The
                      automated messages served as a convenient way for the team to monitor the
                      assets and rooms being rendered, helping refine the process through testing
                      and experimentation. The Slack notifications also served as an alarm for the
                      render failures.
                    </p>
                  </div>

                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        Render request failed scenario
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={decoristSix} alt="jobbox" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      <span style={{fontWeight: '700'}}>Remote Instance Optimization:</span> we were
                      able to greatly optimize the performance and cost of generating models and
                      images using remote machines and configurations.
                    </p>
                    <br />
                    <p className="description">
                      <span style={{fontWeight: '700'}}>Optimization of Models:</span> we developed
                      our own Max script and integrated a built-in asset decimation tool named
                      Simplygon to minimize the vertex count of the Unity models and convert high
                      quality textures to ones that could be used in Unity. This process also
                      involved extensive quality assurance. Every model was checked for texture and
                      geometry issues which could then be addressed.
                    </p>
                  </div>
                  <div className="col-12 p-0 d-flex section-margin">
                    <div
                      className="col-lg-6 col-md-9 col-12 p-0 d-flex justify-content-center"
                      style={{marginLeft: 'auto', marginRight: 'auto'}}
                    >
                      <img className="wrap-image" src={decoristSeven} alt="jobbox" />
                    </div>
                  </div>

                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      We were able to meet all the objectives defined by the Decorist’s product
                      team. The time taken to design a room was successfully reduced to 30-40 mins,
                      including the time required to load the item models being used in a room and
                      resolve any performance issues that might slow down the app.
                    </p>
                    <br />
                    <p className="description">
                      The time taken to generate photorealistic renders of a fully furnished room
                      was reduced to an average 10-15 minutes per room. The entire process including
                      the room design and rendering which erstwhile took around two days could now
                      be completed in less than two hours only.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-6 image-description text-center mt-3  mt-lg-0 mb-0">
                      A fully furnished room in Unity
                    </div>
                    <div className="col-6 image-description text-center mt-3 mt-lg-0 mb-0">
                      The room’s final renders
                    </div>
                    <div className="col-6">
                      <img
                        className="w-100 my-4  "
                        src={decoristEightA}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-6">
                      <img
                        className="w-100 my-4  "
                        src={decoristEightB}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-6">
                      <img
                        className="w-100 my-4  "
                        src={decoristEightC}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-6">
                      <img
                        className="w-100 my-4  "
                        src={decoristEightD}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-6">
                      <img
                        className="w-100 my-4 mb-lg-0 "
                        src={decoristEightE}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-6">
                      <img
                        className="w-100 my-4 mb-lg-0 "
                        src={decoristEightF}
                        alt="bed bath and beyond"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      The project involved significant collaboration with the Decorist team through
                      all stages of the development lifecycle. During the whole course, we provided
                      Decorist our services in Unity app development, backend development, UI and UX
                      design, project management and quality assurance.
                    </p>
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      It has been a distinct pleasure working with the Tintash team. The product
                      solves a critical conversion issue by producing photo-realistic images that
                      are comparable (sometimes better) to those created by a 3D artist. Even though
                      the Room Builder team is located on another continent, we've been able to
                      collaborate effectively and efficiently; the Tintash team has even made
                      suggestions on newer technologies to us.
                    </p>
                    <h2 className="review-name" style={{fontWeight: 'bold'}}>
                      Hima Sunkara
                    </h2>
                    <p className="review-name mt-0">SVP, Product and Technology, Decorist</p>
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>
          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Decorist;
